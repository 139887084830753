<template>
    <div class="dark:bg-gray-800">
        <Nav />
        <div class="container pt-10">
            <div v-if="loaded">
                <div class="space-y-10">
                    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div
                            class="px-4 py-5 relative sm:grid sm:grid-cols-2 sm:gap-3 items-center"
                        >
                            <h2 class="text-xl font-medium text-teal-700 relative text-left">
                                {{ currency.name }} - {{ currency.symbol }}
                            </h2>
                            <div
                                class="text-strong text-right inline-flex items-center justify-end"
                                :class="{
                                    'text-teal-600': currency.status === 'up',
                                    'text-red-600': currency.status === 'down',
                                }"
                            >
                                Market value: {{ currency.price }} $CAD
                                <svg
                                    v-if="currency.status === 'down'"
                                    class="h-4 w-4 text-red-600"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M19 14l-7 7m0 0l-7-7m7 7V3"
                                    />
                                </svg>
                                <svg
                                    v-else-if="currency.status === 'up'"
                                    class="h-4 w-4 text-teal-600"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M5 10l7-7m0 0l7 7m-7-7v18"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div class="border-t border-gray-200">
                            <div
                                class="bg-gray-50 px-6 py-2 sm:grid sm:grid-cols-4 gap-4 items-center"
                                v-if="currency.stakeMode"
                            >
                                <label
                                    for="stakeQuantity"
                                    class="text-sm text-left font-medium text-gray-700"
                                >
                                    Staked {{ currency.symbol }}
                                </label>
                                <input
                                    type="text"
                                    id="stakeQuantity"
                                    v-model="currency.stakeQuantity"
                                    autocomplete="chrome-off"
                                    required
                                    class="focus:ring-teal-500 focus:border-teal-500 block border-gray-300 rounded-md"
                                />

                                <div class="text-left" v-if="stakeValue">{{ stakeValue }}$CAD</div>
                                <div class="sm:col-span-1 col-span-6 text-right" v-if="stakeValue">
                                    <button
                                        type="submit"
                                        @click.prevent="claim"
                                        class="inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    >
                                        Claim
                                    </button>
                                </div>
                            </div>
                            <div
                                class="bg-gray-50 px-6 py-2 sm:grid sm:grid-cols-4 gap-4 items-center text-left"
                                v-if="currency.quantity"
                            >
                                <label for="quantity" class="text-sm font-medium text-gray-700">
                                    Current value
                                </label>
                                <div
                                    class="text-left"
                                    :class="{
                                        'text-teal-600': amount >= invest,
                                        'text-red-600': amount < invest,
                                    }"
                                >
                                    {{ amount }} $CAD
                                </div>
                            </div>
                            <div
                                class="bg-gray-200 px-6 py-2 sm:grid sm:grid-cols-4 gap-4 items-center"
                            >
                                <h3 class="text-lg  font-medium text-teal-800 text-left">
                                    Transactions
                                </h3>
                                <div class="text-left flex">
                                    <span v-if="sortedTransactions.length">{{ invest }} $CAD </span>
                                </div>
                                <div class="text-left flex">
                                    <span v-if="sortedTransactions.length">
                                        {{ amountReceived }} {{ currency.symbol }}
                                    </span>
                                </div>
                                <button
                                    type="submit"
                                    @click.prevent="openTransactionModal"
                                    class="inline-flex justify-center py-1 px-1 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                >
                                    New transaction
                                </button>
                            </div>
                            <template v-if="sortedTransactions.length">
                                <div
                                    v-for="(transaction, index) in sortedTransactions"
                                    class="px-6 py-2 sm:grid sm:grid-cols-4 gap-4 items-center"
                                    :key="transaction.key"
                                    :class="{ 'bg-gray-50': index % 2 === 0 }"
                                >
                                    <div class="text-left text-sm font-medium text-gray-700">
                                        {{ toDate(transaction.date) }}
                                    </div>
                                    <div class="text-left" v-if="transaction.type === 'claim'">
                                        Claim
                                    </div>
                                    <div class="text-left" v-else>
                                        {{ transaction.amount }} $CAD
                                    </div>
                                    <div class="text-left">
                                        {{ transaction.received }} {{ currency.symbol }}
                                    </div>
                                    <div class="sm:col-span-1 col-span-6 text-right">
                                        <button
                                            type="submit"
                                            @click.prevent="deleteTransaction(transaction.key)"
                                            class="inline-flex justify-center py-1 px-2 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div
                            class="px-4 py-3 bg-gray-600 sm:grid sm:grid-cols-3 sm:gap-6 sm:px-6 items-center"
                        >
                            <div class="text-left text-gray-50 text-xs">
                                <div
                                    class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                                >
                                    <input
                                        type="checkbox"
                                        name="toggle"
                                        v-model="currency.stakeMode"
                                        @change="setStakeMode"
                                        id="stakeMode"
                                        class="toggle-checkbox absolute block w-6 h-6 rounded-full
                                    bg-white border-4 appearance-none cursor-pointer"
                                    />
                                    <label
                                        for="stakeMode"
                                        class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                                    ></label>
                                </div>
                                <label for="toggle" class="text-xs text-gray-100">Stake mode</label>
                            </div>
                            <div class="text-gray-100 text-xs text-center">
                                Last update: {{ lastUpdate }}
                            </div>
                            <div class=" text-right">
                                <button
                                    type="submit"
                                    @click.prevent="deleteCurrency"
                                    class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                >
                                    Delete
                                </button>
                                <button
                                    type="submit"
                                    @click.prevent="updateCurrency"
                                    class="inline-flex justify-center py-2 px-4 ml-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-600"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="fixed z-10 inset-0 overflow-y-auto" v-if="displayTransactionModal">
                    <div
                        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
                    >
                        <div
                            class="fixed inset-0 transition-opacity"
                            aria-hidden="true"
                            @click.prevent="closeTransactionModal"
                        >
                            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>

                        <span
                            class="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                            >&#8203;</span
                        >

                        <div
                            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                        >
                            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div class="sm:flex sm:items-start">
                                    <div
                                        class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-teal-100 sm:mx-0 sm:h-10 sm:w-10"
                                    >
                                        <svg
                                            class="h-6 w-6 text-teal-600"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                    </div>
                                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3
                                            class="text-lg mt-2 leading-6 font-medium text-gray-900 flex items-center"
                                        >
                                            New Transaction
                                        </h3>
                                        <div class="mt-5">
                                            <div class="mt-5 sm:grid sm:grid-cols-6 sm:gap-6">
                                                <label
                                                    for="amount"
                                                    class="col-span-2 text-left text-sm font-medium text-gray-700 flex items-center"
                                                >
                                                    Amount $CAD
                                                </label>
                                                <input
                                                    type="text"
                                                    id="amount"
                                                    v-model="newTransaction.amount"
                                                    required
                                                    class="focus:ring-teal-500 col-span-3 focus:border-teal-500 block w-full border-gray-300 rounded-md"
                                                />
                                            </div>
                                            <div class="mt-5 sm:grid sm:grid-cols-6 sm:gap-6">
                                                <label
                                                    for="received"
                                                    class="col-span-2 text-left text-sm font-medium text-gray-700 flex items-center"
                                                >
                                                    Received {{ currency.symbol }}
                                                </label>
                                                <input
                                                    type="text"
                                                    id="received"
                                                    v-model="newTransaction.received"
                                                    required
                                                    class="focus:ring-teal-500 col-span-3 focus:border-teal-500 block w-full border-gray-300 rounded-md"
                                                />
                                            </div>
                                            <div class="mt-5 sm:grid sm:grid-cols-6 sm:gap-6">
                                                <label
                                                    for="amount"
                                                    class="col-span-2 text-left text-sm font-medium text-gray-700 flex items-center"
                                                >
                                                    Date
                                                </label>
                                                <vCalendar @dayclick="onDayClick" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    @click.prevent="addTransaction"
                                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-600 text-base font-medium text-white hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    Add
                                </button>
                                <button
                                    type="button"
                                    @click.prevent="closeTransactionModal"
                                    class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="dark:bg-gray-800 min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-6"
            >
                <Loader />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { computed, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { firebaseCurrencies, firebaseTransactions } from '@/Firebase'

import Loader from '@/components/Loader'
import Nav from '@/components/Nav'

import errorHandler from '@/helpers/errorHandler'

const oneMinute = 1000 * 60

export default {
    components: {
        Loader,
        Nav,
    },

    setup() {
        const route = useRoute()
        const router = useRouter()
        const state = reactive({
            currency: {},
            displayTransactionModal: false,
            loaded: false,
            newTransaction: {},
            transactions: [],
        })

        const addTransaction = async () => {
            const date = new Date(state.newTransaction.date).getTime()

            try {
                const document = await firebaseTransactions.add({
                    amount: state.newTransaction.amount,
                    received: state.newTransaction.received,
                    date,
                    updatedAt: Date.now(),
                    type: state.newTransaction.type || 'transaction',
                    currencyId: state.currency.key,
                    symbol: state.currency.symbol,
                })
                const transaction = await document.get()

                if (transaction.id) {
                    state.transactions = [
                        ...state.transactions,
                        { ...state.newTransaction, key: transaction.id },
                    ]
                    closeTransactionModal()
                }

                if (!state.currency.stakeMode) {
                    state.currency.quantity = state.transactions.reduce(
                        (prev, current) => prev + parseFloat(current.received),
                        0
                    )
                    updateCurrency()
                }
            } catch (error) {
                errorHandler(error)
            }
        }

        const claim = () => {
            state.newTransaction.date = Date.now()
            state.newTransaction.amount = 0
            state.newTransaction.received = state.currency.stakeQuantity
            state.newTransaction.type = 'claim'
            addTransaction()
            state.currency.stakeQuantity = null
            updateCurrency()
        }

        const deleteCurrency = async () => {
            try {
                await state.transactions.forEach(async transaction => {
                    await deleteTransaction(transaction.key, true)
                })
                await firebaseCurrencies.doc(route.params.id).delete()

                router.push({ name: 'Dashboard' })
            } catch (error) {
                errorHandler(error)
            }
        }

        const deleteTransaction = async (id, forceDelete = false) => {
            try {
                await firebaseTransactions.doc(id).delete()

                await loadTransactions()
                if (!forceDelete && state.transactions.length === 0) {
                    state.currency.quantity = 0
                    updateCurrency()
                }
            } catch (error) {
                errorHandler(error)
            }
        }

        const loadCurrency = async () => {
            try {
                const document = await firebaseCurrencies.doc(route.params.id)
                const currency = await document.get()
                state.currency = { key: currency.id, ...currency.data() }
                state.loaded = true
                if (Date.now() - state.currency.updatedAt > oneMinute || !state.currency.price) {
                    loadPrice()
                }
                loadTransactions()
            } catch (error) {
                errorHandler(error)
            }
        }

        const loadTransactions = async () => {
            try {
                const collection = await firebaseTransactions
                    .where('currencyId', '==', state.currency.key)
                    .get()

                state.transactions = await collection.docs.map(doc => ({
                    key: doc.id,
                    ...doc.data(),
                }))
            } catch (error) {
                errorHandler(error)
            }
        }

        const loadPrice = async () => {
            try {
                const price = await axios.get(
                    `https://api.coingecko.com/api/v3/simple/price?ids=${state.currency.id}&vs_currencies=CAD`
                )
                if (price.data[state.currency.id]) {
                    const newPrice = price.data[state.currency.id].cad
                    if (!state.currency.price) {
                        state.currency.status = 'flat'
                    } else {
                        state.currency.status = state.currency.price > newPrice ? 'down' : 'up'
                    }

                    state.currency.price = price.data[state.currency.id].cad
                    updateCurrency()
                }
            } catch (error) {
                errorHandler(error)
            }
        }

        const openTransactionModal = () => {
            state.displayTransactionModal = true
        }

        const closeTransactionModal = () => {
            state.displayTransactionModal = false
            state.newTransaction = {}
        }

        const onDayClick = event => {
            state.newTransaction.date = event.date
        }

        const setStakeMode = () => {
            if (!state.currency.stakeMode) {
                state.currency.quantity = state.transactions.reduce(
                    (prev, current) => prev + parseFloat(current.received),
                    0
                )
            }
            updateCurrency()
        }

        const toDate = timestamp => {
            return new Date(timestamp).toISOString().split('T')[0]
        }

        const updateCurrency = async () => {
            const updatedAt = Date.now()
            try {
                await firebaseCurrencies.doc(route.params.id).update(state.currency)
                state.currency.updatedAt = updatedAt
            } catch (error) {
                errorHandler(error)
            }
        }

        loadCurrency()
        return {
            ...toRefs(state),

            amount: computed(() =>
                (state.currency.stakeQuantity
                    ? state.currency.price *
                      (parseFloat(state.currency.quantity) +
                          parseFloat(state.currency.stakeQuantity))
                    : state.currency.price * state.currency.quantity
                ).toFixed(2)
            ),
            amountReceived: computed(() =>
                state.transactions.reduce(
                    (prev, current) => parseFloat(prev) + parseFloat(current.received),
                    0
                )
            ),
            invest: computed(() =>
                state.transactions.reduce(
                    (prev, current) => parseFloat(prev) + parseFloat(current.amount),
                    0
                )
            ),
            lastUpdate: computed(() =>
                new Date(state.currency.updatedAt).toLocaleString('fr-CA', {
                    timeZone: 'America/Toronto',
                })
            ),
            sortedTransactions: computed(() => state.transactions.sort((a, b) => a.date - b.date)),
            stakeValue: computed(() =>
                state.currency.stakeQuantity
                    ? (state.currency.stakeQuantity * state.currency.price).toFixed(2)
                    : null
            ),

            addTransaction,
            claim,
            closeTransactionModal,
            deleteCurrency,
            deleteTransaction,
            onDayClick,
            openTransactionModal,
            setStakeMode,
            toDate,
            updateCurrency,
        }
    },
}
</script>

<style lang="scss" scoped></style>
